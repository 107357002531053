import React, { Component } from "react";
import AppContext from "../../../context/AppContext";
import styled from "styled-components";
import {
    Row,
    Column,
    Loading,
    Tooltip,
    Panel,
    SelectField,
} from "@cortexglobal/rla-components";

import { team } from "../../../config/statusGroups";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import DetailRow from "../../../components/DetailRow";
import InviteStaff from "../staff/InviteStaff";
import ExitSurvey from "../staff/ExitSurvey";
import {
    BodyRow,
    BodyColumn,
    BodyLabel,
    RowWrapper,
    HeaderColumn,
    HeaderRow,
    StyledUpdateStatusButton,
} from "../../../components/table/index";
import ShowHideButton from "../../../components/table/ShowHideButton";

const RewardColumn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    // border:2px solid red;
    span {
        flex: 1;
        display: flex;
        flex-direction: column;
        font-weight: 800;
        background-color: #efefef;
        height: 60px;
        align-content: center;
        justify-content: center;
        &:first-of-type {
            background-color: #fafafa;
        }
        &:last-of-type {
            background-color: #d0d0d0;
        }
        h4 {
            font-size: 0.8em;
            margin: 0px;
            font-weight: normal;
            color: #000000;
            opacity: 0.5;
        }
    }
`;

const ProbationStatus = styled.span`
    margin-left: 0.5rem;
`;

class TeamHome extends Component {
    state = {
        showing: "",
        columnGroup: "rewards",
    };
    renderDetailsColumn = (member) => {
        switch (this.state.columnGroup) {
            case "comparison":
                return (
                    <RewardColumn>
                        <span>
                            <h4>Total Games</h4>
                            {member.comparison_game_count}
                        </span>
                        <span>
                            <h4>Best Score</h4>
                            {member.comparison_game_best
                                ? member.comparison_game_best
                                : "-"}
                        </span>
                    </RewardColumn>
                );
            case "badges":
                return (
                    <RewardColumn>
                        {member.badges.map((badge) => {
                            return (
                                <span>
                                    <h4>{badge.name}</h4>
                                    {badge.awarded}
                                </span>
                            );
                        })}
                    </RewardColumn>
                );
            case "quiz":
                return (
                    <RewardColumn>
                        <span>
                            <h4>Quizzes</h4>
                            {member.quiz_count}
                        </span>
                        <span>
                            <h4>Questions</h4>
                            {member.question_count}
                        </span>
                        <span>
                            <h4>Correct</h4>
                            {member.question_correct_count}
                        </span>
                    </RewardColumn>
                );
            case "rewards":
            default:
                return (
                    <RewardColumn>
                        <span>
                            <h4>Redeemed</h4>
                            &pound;
                            {member.points_balance.redeemed}
                        </span>
                    </RewardColumn>
                );
        }
    };
    render() {
        return (
            <Row>
                <AppContext.Consumer>
                    {({ staff, loading, user, group }) => {
                        if (loading) {
                            return <Loading align="center" />;
                        }
                        const activeStaff = staff.data.filter((member) => {
                            return team.indexOf(member.status) !== -1;
                        });
                        return (
                            <React.Fragment>
                                <Row>
                                    <Column large={8}>
                                        <PageTitle
                                            title="My Team"
                                            subtitle="Your current team status"
                                        />
                                    </Column>
                                    <Column
                                        large={4}
                                        style={{ marginTop: "1rem" }}
                                        collapse
                                    >
                                        <ExitSurvey />
                                        <InviteStaff />
                                    </Column>
                                </Row>

                                <Row
                                    style={{
                                        marginTop: "1rem",
                                        marginBottom: "1rem",
                                    }}
                                >
                                    <Column>
                                        Please be aware your Teams' Elite
                                        accounts are reliant on live and
                                        accurate S-Gate and TMSi data. To ensure
                                        your Team Members remain active on Elite
                                        and continue to have access to all the
                                        programme benefits and rewards, please
                                        make sure they regularly login into
                                        S-Gate. S-Gate accounts are removed
                                        after one year of inactivity which in
                                        turn will result in their Elite account
                                        being disabled until a new S-Gate is
                                        created and re-linked.
                                    </Column>
                                </Row>

                                {activeStaff.length > 0 ? (
                                    <React.Fragment>
                                        <HeaderRow>
                                            <HeaderColumn large={2}>
                                                Name
                                            </HeaderColumn>
                                            <HeaderColumn large={2}>
                                                Job Title
                                            </HeaderColumn>
                                            <HeaderColumn large={2}>
                                                Status
                                            </HeaderColumn>
                                            <HeaderColumn large={3}>
                                                <SelectField
                                                    name="columnGroup"
                                                    showEmptyOption={false}
                                                    value={
                                                        this.state.columnGroup
                                                    }
                                                    onChange={({
                                                        name,
                                                        value,
                                                    }) => {
                                                        this.setState({
                                                            [name]: value,
                                                        });
                                                    }}
                                                    options={[
                                                        {
                                                            value: "rewards",
                                                            text: "Rewards",
                                                        },
                                                        {
                                                            value: "quiz",
                                                            text: "Quiz",
                                                        },
                                                        {
                                                            value: "comparison",
                                                            text: "This or That",
                                                        },
                                                        {
                                                            value: "badges",
                                                            text: "Badges",
                                                        },
                                                    ]}
                                                />
                                            </HeaderColumn>
                                            <HeaderColumn large={2}>
                                                Actions
                                            </HeaderColumn>
                                        </HeaderRow>
                                        {activeStaff.map((member) => {
                                            const showingDetail =
                                                this.state.showing ===
                                                member.uuid;
                                            return (
                                                <RowWrapper key={member.uuid}>
                                                    <BodyRow showingDetail>
                                                        <BodyColumn large={2}>
                                                            <BodyLabel>
                                                                Name:
                                                            </BodyLabel>
                                                            {member.first_name}{" "}
                                                            {member.last_name}
                                                        </BodyColumn>
                                                        <BodyColumn large={2}>
                                                            <BodyLabel>
                                                                Role:
                                                            </BodyLabel>
                                                            {member.role &&
                                                                member.role
                                                                    .name}
                                                        </BodyColumn>
                                                        <BodyColumn large={2}>
                                                            <BodyLabel>
                                                                Status:
                                                            </BodyLabel>
                                                            {
                                                                member.status_wording
                                                            }{" "}
                                                            {member.probation_status && (
                                                                <Tooltip
                                                                    text={
                                                                        member
                                                                            .probation_status
                                                                            .description
                                                                    }
                                                                >
                                                                    <ProbationStatus>
                                                                        (
                                                                        {
                                                                            member
                                                                                .probation_status
                                                                                .title
                                                                        }
                                                                        )
                                                                    </ProbationStatus>
                                                                </Tooltip>
                                                            )}
                                                        </BodyColumn>
                                                        <BodyColumn large={3}>
                                                            {this.renderDetailsColumn(
                                                                member
                                                            )}
                                                        </BodyColumn>
                                                        <BodyColumn large={3}>
                                                            {/* {member.status ===
                                                                "active" && (
                                                                <Column
                                                                    medium={4}
                                                                >
                                                                    <StyledUpdateStatusButton
                                                                        newStatus="account-disabled"
                                                                        user={
                                                                            member
                                                                        }
                                                                    >
                                                                        Disable
                                                                    </StyledUpdateStatusButton>
                                                                </Column>
                                                            )}
                                                            {member.status ===
                                                                "account-disabled" && (
                                                                <Column
                                                                    medium={4}
                                                                >
                                                                    <StyledUpdateStatusButton
                                                                        newStatus="active"
                                                                        user={
                                                                            member
                                                                        }
                                                                    >
                                                                        Enable
                                                                    </StyledUpdateStatusButton>
                                                                </Column>
                                                            )} */}
                                                            <Column medium={4}>
                                                                <ShowHideButton
                                                                    showDetails={() => {
                                                                        const showing =
                                                                            showingDetail
                                                                                ? ""
                                                                                : member.uuid;
                                                                        this.setState(
                                                                            (
                                                                                state
                                                                            ) => ({
                                                                                showing,
                                                                            })
                                                                        );
                                                                    }}
                                                                    showingDetail={
                                                                        showingDetail
                                                                    }
                                                                />
                                                            </Column>
                                                            <Column medium={8}>
                                                                <StyledUpdateStatusButton
                                                                    type="alert"
                                                                    newStatus="account-deleted"
                                                                    user={
                                                                        member
                                                                    }
                                                                    confirm="Are you sure you want to delete this user?"
                                                                >
                                                                    Delete User
                                                                </StyledUpdateStatusButton>
                                                            </Column>
                                                        </BodyColumn>
                                                    </BodyRow>

                                                    <DetailRow
                                                        member={member}
                                                        group={group}
                                                        shown={showingDetail}
                                                    />
                                                </RowWrapper>
                                            );
                                        })}
                                    </React.Fragment>
                                ) : (
                                    <Panel
                                        style={{
                                            width: "66%",
                                            borderRadius: 0,
                                            boxShadow: "none",
                                        }}
                                    >
                                        <Row>
                                            <Column>
                                                <h4>
                                                    No active staff accounts
                                                    available
                                                </h4>
                                                <p>
                                                    There are currently no staff
                                                    who have completed the
                                                    activation process. You can
                                                    invite staff members using
                                                    the ‘Invite a
                                                    Technician/Apprentice’ link
                                                    at the top of this page.
                                                    Once they have completed the
                                                    sign-up process, they will
                                                    appear here for you to
                                                    manage their accounts and
                                                    check on their progress.
                                                </p>
                                            </Column>
                                        </Row>
                                    </Panel>
                                )}
                            </React.Fragment>
                        );
                    }}
                </AppContext.Consumer>
            </Row>
        );
    }
}

export default TeamHome;
